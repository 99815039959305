<template>
  <div>
    <property-tax-demand-notice-preview
      v-if="openPropertyTaxDemandNoticePreviewModal"
      @cancelPrint="openPropertyTaxDemandNoticePreviewModal = false"
      :property="propertyToPreview"
      :assessment_year="assessment_year"
    >
    </property-tax-demand-notice-preview>
    <property-tax-demand-notice-batch-preview
      v-if="openPropertyTaxDemandNoticeBatchPreviewModal"
      @cancelPrint="
        openPropertyTaxDemandNoticeBatchPreviewModal = false;
        openPropertyTaxDemandNoticeBatchModal = true;
      "
      :selectedLocation="selectedLocation"
      :assessment_year="assessment_year"
      :properties="propertiesToPreview"
    >
    </property-tax-demand-notice-batch-preview>
    <property-tax-demand-notice-batch-modal
      v-if="openPropertyTaxDemandNoticeBatchModal"
      @close-modal="openPropertyTaxDemandNoticeBatchModal = false"
      @print-batch="printBatch"
      @open-batch-preview="printBatchFromIds"
    >
    </property-tax-demand-notice-batch-modal>
    <teleport to=".modal">
      <authorize-modal
        v-if="showPropertyDetailsModal"
        :title="'Property Details'"
        @hideModal="showPropertyDetailsModal = false"
      >
        <div class="text-left border-t border-gray-100 overflow-y-auto h-96">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Area SQM</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.area_sqm + "sqm" || "N/A" }}
              </dd>
            </div>

            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Building ID</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.unique_id || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Completed</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.completed || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Enumeration Date
              </dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.enum_date || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Finished</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.finished || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">No Of Floors</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.no_of_flor || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">No Of Occupants</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.no_of_occu || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Occupation Type</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.occup_type || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Parcel Ref</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.parcel_ref || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Roof Mater</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.roof_mater || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Roof Type</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.roof_type || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Use</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.the_use || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Solar</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.solar || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Compound Type</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.type_com || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Wall Mater</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.wall_mater || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Year Finished</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ propertyDetails.year_finis || "N/A" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Building Photo</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                <!-- {{ propertyDetails.bldg_photo }} -->
                <!-- <img
                  @load="propImageLoading = false"
                  :src="
                    loadImage(
                      `https://102.135.209.198/${propertyDetails.bldg_photo}`
                    )
                  "
                  alt=""
                /> -->
                <div v-if="propImageLoading">
                  <span>loading image, please wait...</span>
                </div>
                <img
                  v-else
                  @load="propImageLoading = false"
                  :src="`https://102.135.209.198/${propertyDetails.bldg_photo}`"
                  class="w-32 h-32 rounded"
                  alt="Property Image"
                />
              </dd>
            </div>
          </dl>
        </div>
      </authorize-modal>
    </teleport>

    <div class="mt-8 overflow-x-hidden pb-20">
      <hr />
      <p class="mt-8 text-gray-500 font-semibold">Select property type</p>
      <div class="flex items-center gap-8 mt-2 mb-8">
        <label class="inline-flex items-center">
          <input
            @change="propertyType = 'Building'"
            :checked="propertyType === 'Building'"
            type="checkbox"
            value="Building"
            name="propertyType"
            class="h-5 w-5"
          />
          <span class="ml-2 text-gray-700"> Building </span>
        </label>
        <label class="inline-flex items-center">
          <input
            @change="propertyType = 'Parcel'"
            :checked="propertyType === 'Parcel'"
            value="Parcel"
            type="checkbox"
            name="propertyType"
            class="h-5 w-5"
          />
          <span class="ml-2 text-gray-700"> Parcel </span>
        </label>
      </div>
      <hr />

      <div
        class="flex flex-col sm:flex-row sm:items-end sm:space-x-8 sm:space-y-0 space-y-6 mb-10"
      >
        <div>
          <p class="mt-8 text-gray-500 font-semibold">Select location</p>
          <object-drop-down
            class="mt-2"
            :items="LgasInBayelsa"
            :color="'gray'"
            :title="''"
            :classList="[' w-full sm:w-80 rounded border-2 border-gray-500 ']"
            @item-selected="selectLocation"
          />
        </div>

        <div v-if="enableAssessmentYear" class="relative sm:w-52 mb-2 sm:mb-0">
          <div class="flex items-center justify-between">
            <label for="assessment_year" class="text-gray-500 font-semibold"
              >Assessment Year</label
            >
          </div>
          <input
            type="number"
            v-model="assessment_year"
            autocomplete="off"
            class="w-full bg-white rounded border-2 border-gray-500 focus:ring-2 focus:ring-gray-700 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>
        <button
          type="button"
          @click.prevent="fetchProperties"
          :disabled="fetchingProperties"
          class="px-4 py-3 sm:w-48 font-semibold text-white text-lg sm:text-sm transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-blue-700 hover:from-blue-600 to-blue-600 hover:to-blue-700 focus:ring focus:ring-blue-700 focus:outline-none"
        >
          <div v-if="!fetchingProperties">Load properties</div>
          <div
            v-else
            class="flex items-center sm:text-sm space-x-4 justify-center"
          >
            <i class="fas fa-circle-notch py-1 animate-spin"></i>
          </div>
        </button>
        <button
          v-if="enablePrint"
          type="button"
          @click.prevent="openPropertyTaxDemandNoticeBatchModal = true"
          class="px-4 py-3 sm:w-48 font-semibold text-white text-lg sm:text-sm transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-blue-700 hover:from-blue-600 to-blue-600 hover:to-blue-700 focus:ring focus:ring-blue-700 focus:outline-none"
        >
          <div>Print batch</div>
        </button>
      </div>
      <hr />
      <div>
        <div class="mt-8">
          <h2 class="mb-4 text-2xl text-gray-600 font-semibold leading-tight">
            Properties
          </h2>

          <div
            class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:w-full"
          >
            <div
              class="inline-block min-w-full shadow rounded-lg overflow-hidden"
            >
              <table class="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Building ID
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Area
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Developed
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    >
                      Use
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="property in properties.records"
                    :key="property.gid"
                  >
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <div class="flex items-center">
                        <p class="text-gray-900 whitespace-no-wrap">
                          {{ property.unique_id || "N/A" }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <div class="flex items-center">
                        <p class="text-gray-900 whitespace-no-wrap">
                          {{ property.district || "N/A" }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ property.area_sqm + " sqm" || "N/A" }}
                      </p>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ property.developed || "N/A" }}
                      </p>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ property.the_use || "N/A" }}
                      </p>
                    </td>
                    <td
                      class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <button
                        v-if="enablePrint"
                        @click.stop="printDemandNotice(property)"
                        :disabled="property_gid === property.gid"
                        class="px-2 py-2 rounded transition duration-300 focus:outline-none border-2 border-blue-500 text-blue-500 hover:bg-blue-700 hover:text-white"
                      >
                        <svg
                          v-if="property_gid !== property.gid"
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                            clip-rule="evenodd"
                          />
                        </svg>

                        <i
                          v-else
                          :class="`lg:focus:shadow-sm w-5 text-center fas fa-circle-notch text-lg animate-spin`"
                        ></i>
                      </button>
                      <button
                        v-else
                        @click.stop="showPropertyDetails(property)"
                        class="focus:outline-none transition-all duration-200 ease-linear text-blue-primary font-semibold border-2 border-blue-primary-dark px-2 py-1 rounded-md hover:bg-blue-primary-dark hover:text-white hover:font-semibold"
                      >
                        view
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                class="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between"
              >
                <div>
                  <div v-if="!fetchingProperties" class="flex items-center">
                    <button
                      v-show="properties.records"
                      :disabled="page < 2"
                      @click="gotoPrevRecords"
                      type="button"
                      class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100"
                    >
                      <svg
                        width="9"
                        fill="currentColor"
                        height="8"
                        class=""
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"
                        ></path>
                      </svg>
                    </button>

                    <button
                      v-show="properties.records"
                      @click="gotoNextRecords"
                      type="button"
                      class="w-full p-4 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100"
                    >
                      <svg
                        width="9"
                        fill="currentColor"
                        height="8"
                        class=""
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div
                    v-else
                    class="flex items-center space-x-4 justify-center"
                  >
                    <i class="fas fa-circle-notch animate-spin"></i>
                    <span>Please wait...</span>
                  </div>
                </div>
                <p
                  v-if="!fetchingProperties && properties.records"
                  class="text-sm text-gray-500"
                >
                  Page {{ page }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ObjectDropDown from "../shared/ObjectDropDown.vue";
import PropertyTaxDemandNoticePreview from "./PropertyTaxDemandNoticePreview.vue";
import PropertyTaxDemandNoticeBatchPreview from "./PropertyTaxDemandNoticeBatchPreview.vue";
import AuthorizeModal from "../AuthorizeModal.vue";
import PropertyTaxDemandNoticeBatchModal from "./PropertyTaxDemandNoticeBatchModal.vue";
import { emitter } from "../../main";
// import { Axios } from "../../helpers/axios-config";

export default {
  components: {
    ObjectDropDown,
    PropertyTaxDemandNoticePreview,
    PropertyTaxDemandNoticeBatchPreview,
    AuthorizeModal,
    PropertyTaxDemandNoticeBatchModal,
  },
  props: {
    enablePrint: {
      type: Boolean,
      required: true,
      default: false,
    },
    enableAssessmentYear: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const propertyType = ref("Building");
    const pageSize = ref(50);
    const page = ref(1);
    const property_gid = ref("");
    const selectedLocation = ref("");
    const fetchingProperties = ref(false);
    const printingBatch = ref(false);
    const propImageLoading = ref(false);
    const showPropertyDetailsModal = ref(false);
    const assessment_year = ref(new Date().getFullYear());
    const propertyDetails = ref({});
    // const preparingNotice = ref(false);
    const store = useStore();
    const openPropertyTaxDemandNoticeBatchModal = ref(false);
    const openPropertyTaxDemandNoticePreviewModal = ref(false);
    const openPropertyTaxDemandNoticeBatchPreviewModal = ref(false);
    const propertyToPreview = ref({});
    const propertiesToPreview = ref([]);

    const LgasInBayelsa = computed(
      () => store.getters["assessment/getBayelsaLgas"]
    );
    const properties = computed(
      () => store.getters["assessment/getAssessmentProperties"]
    );

    function selectLocation(item) {
      selectedLocation.value = item;
    }
    function gotoNextRecords() {
      page.value++;

      fetchProperties();
    }
    function gotoPrevRecords() {
      if (page.value > 1) page.value--;

      fetchProperties();
    }
    async function fetchProperties() {
      try {
        if (assessment_year.value == "") throw "Please enter assessment year";

        fetchingProperties.value = true;
        await store.dispatch("assessment/getProperties", {
          ...selectedLocation.value,
          pageSize: pageSize.value,
          page: page.value,
        });
      } catch (error) {
        console.log(error);
        Swal.fire({ title: "Revenue House", icon: "error", text: error });
      } finally {
        fetchingProperties.value = false;
      }
    }
    async function printDemandNotice(property) {
      try {
        property_gid.value = property.gid;
        let result = await store.dispatch("assessment/getPropertyTaxResult", {
          url: "assessment/propertyTax/calculate",
          data: {
            parcel_ref: property.parcel_ref,
            unique_id: property.unique_id,
            assessment_year: assessment_year.value,
          },
        });

        propertyToPreview.value = result;
        openPropertyTaxDemandNoticePreviewModal.value = true;
      } catch (error) {
        Swal.fire({ title: "Revenue House", icon: "error", text: error });
      } finally {
        property_gid.value = "";
      }
    }
    async function printBatch() {
      try {
        if (assessment_year.value == "") throw "Please enter assessment year";

        printingBatch.value = true;
        let result = await store.dispatch("assessment/getPropertyTaxResult", {
          url: "assessment/propertyTax/batch",
          data: {
            ...selectedLocation.value,
            pageSize: pageSize.value,
            page: page.value,
            assessment_year: assessment_year.value,
          },
        });

        propertiesToPreview.value = result;
        openPropertyTaxDemandNoticeBatchModal.value = false;
        openPropertyTaxDemandNoticeBatchPreviewModal.value = true;
        // Swal.fire({ title: "Revenue House", icon: "info", text: "Batch printing not enabled at the moment due to data inconsistency..." });
      } catch (error) {
        Swal.fire({ title: "Revenue House", icon: "error", text: error });
        emitter.emit("stop-progress");
      } finally {
        property_gid.value = "";
        printingBatch.value = false;
      }
    }
    async function printBatchFromIds(result) {
      try {
        propertiesToPreview.value = result;
        openPropertyTaxDemandNoticeBatchModal.value = false;
        openPropertyTaxDemandNoticeBatchPreviewModal.value = true;
        // Swal.fire({ title: "Revenue House", icon: "info", text: "Batch printing not enabled at the moment due to data inconsistency..." });
      } catch (error) {
        Swal.fire({ title: "Revenue House", icon: "error", text: error });
        emitter.emit("stop-progress");
      } finally {
        property_gid.value = "";
        printingBatch.value = false;
      }
    }
    async function showPropertyDetails(property) {
      showPropertyDetailsModal.value = true;
      propImageLoading.value = true;
      propertyDetails.value = property;
    }

    async function loadImage(url) {
      try {
        const res = fetch(url, {
          mode: "cors", // Enables cross-origin requests
        });

        if (!res.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await res.blob();
        return URL.createObjectURL(blob);
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async () => {
      let lgas = await store.getters["assessment/getBayelsaLgas"];
      for (var key in lgas) {
        selectedLocation.value = { areaName: key, areaCode: lgas[key] };
        break;
      }
    });

    return {
      loadImage,
      propertyType,
      showPropertyDetails,
      printBatchFromIds,
      propertyDetails,
      propImageLoading,
      page,
      gotoNextRecords,
      gotoPrevRecords,
      printBatch,
      property_gid,
      properties,
      fetchingProperties,
      printingBatch,
      assessment_year,
      printDemandNotice,
      showPropertyDetailsModal,
      openPropertyTaxDemandNoticePreviewModal,
      openPropertyTaxDemandNoticeBatchModal,
      openPropertyTaxDemandNoticeBatchPreviewModal,
      fetchProperties,
      propertyToPreview,
      propertiesToPreview,
      LgasInBayelsa,
      selectLocation,
      selectedLocation,
    };
  },
};
</script>

<style></style>
