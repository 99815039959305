<template>
  <div class="relative">
    <div class="h-screen w-full fixed z-40 inset-0 overflow-y-auto bg-gray-500">
      <div class="absolute w-full h-screen inset-0 bg-gray-500 opacity-75"></div>
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        </span>
        <div class="inline-block relative overflow-hidden transform transition-all sm:align-middle" role="dialog"
          aria-modal="true" aria-labelledby="modal-headline">
          <div class="w-full">
            <div class="w-full rounded-lg p-2 bg-white shadow">
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-between items-center">
                <h2 class="sm:pl-0 mb-4 sm:mb-0 text-gray-500 font-semibold sm:text-xl">
                  Print Preview
                </h2>
                <div>
                  <button @click="print()" type="button"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Print
                  </button>
                  <button @click="cancelPrint" type="button"
                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cancel
                  </button>
                </div>
              </div>
              <div class="bg-white dark:bg-gray-800">
                <div id="details-holder" class="text-center ">
                  <div class="details-holder relative overflow-x-auto " style="width: 700px !important; height: 990px">
                    <img class="notice-img w-full h-full absolute inset-0 bg-cover bg-no-repeat bg-top"
                      src="https://res.cloudinary.com/uchenna/image/upload/v1656334198/bir-etax/property_tax_template_gw0bwx.png"
                      alt="" srcset="" />
                    <div class="content-holder w-full absolute top-52 pt-4">
                      <div class="sub-content-holder h-auto absolute top-0 left-11 text-left font-medium text-black">
                        <div class="property-use text-sm flex">
                          <div class="uppercase w-36 text-sm text-gray-800">Property use :
                          </div>
                          {{ property.use || 'N/A' }}
                        </div>
                        <div class="property-area text-sm flex">
                          <div class="uppercase w-36 text-sm text-gray-800">property size :
                          </div>
                          {{ property.area_sqm || 'N/A' }} sqm
                        </div>
                        <div class="property-parcel-num text-sm flex">
                          <div class="uppercase w-36 text-sm text-gray-800">Property ID :
                          </div>
                          {{ property.unique_id || 'N/A' }}
                        </div>

                        <div class="property-owner-tin text-sm flex">
                          <div class="uppercase w-36 text-sm text-gray-800">tin : </div>
                          {{ property.tin || "0000000000" }}
                        </div>
                        <div class="property-owner text-sm flex">
                          <div class="uppercase w-36 text-sm text-gray-800">Property owner :
                          </div>
                          {{ property.owner_occu || 'N/A' }}
                        </div>
                        <div class="property-owner-address text-sm flex">
                          <div class="uppercase w-36 text-sm text-gray-800">Address : </div>
                          {{ property.address || 'N/A' }}
                        </div>
                        <div class="property-geo-tag text-sm flex items-start">
                          <div class="uppercase w-36 text-sm text-gray-800">Geo tag : </div>
                          {{ property.geom || 'N/A' }}
                        </div>
                      </div>
                      <img :src="`//images.weserv.nl/?url=197.210.135.122:39/${property.bldg_photo}`"
                        class="w-32 h-32 absolute top-0 right-10 rounded" alt="" srcset="" />

                      <div class="w-full flex flex-row items-center relative mt-64">
                        <p class="tax-year ml-8 text-center pt-3 text-black w-40 font-medium text-sm">
                          {{ property.assessment_year }}
                        </p>
                        <p class="tax-amount ml-1 text-center pt-3 text-black w-40 font-medium text-sm">
                          ₦{{ formatAmount(property.tax_payable) }}
                        </p>
                        <p class="tax-dis-amount  text-center -ml-2 w-40 h-5 text-black font-medium text-sm">
                          ₦{{ formatAmount("0.00") }}
                        </p>
                        <p class="tax-total-amount  text-center w-40 h-5 -ml-4 text-black font-medium text-sm">
                          ₦{{ formatAmount(property.tax_payable) }}
                        </p>
                      </div>

                      <div class="relative -mt-16">

                        <p
                          class="tax-amount-in-words absolute text-center  w-82 h-12 top-28 pt-2 left-48 text-black font-medium text-sm">
                          {{ amount_in_words(property.tax_payable) }}
                        </p>
                      </div>
                      <div class="signature relative h-32" style="margin-top: 410px;">
                        <div class="absolute text-black text-center top-14 left-12">
                          <p class="font-bold border-b border-dashed border-black">{{ property.issue_date
                          }}</p>
                          <p class="text-xs text-gray-700">Issued Date</p>
                        </div>
                        <img
                          :src="'https://res.cloudinary.com/uchenna/image/upload/v1654845594/bir-etax/Mr_Audy_Anthony_s_Signature_j2denf.png'"
                          class=" w-72 absolute top-0 right-10 rounded" alt="" srcset="" />
                        <div class="absolute text-black w-56 top-20 right-10 text-sm border-t-2 border-gray-dark">
                          <p class="uppercase font-medium tracking-tight">Mr. Audy Moningha Anthony</p>
                          <p class="text-xs text-gray-700">Executive Director, Assessment</p>
                        </div>

                        <canvas class="qrcode ml-56" id="qrcode" style="margin-top: 20px !important;">QRCODE</canvas>
                      </div>
                    </div>
                  </div>
                  <!-- Content goes here -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import printJS from "print-js";
import numeral from "numeral";
import { useFormatter } from "../../helpers/formatter";
import QRCode from "qrcode";
import { onMounted } from 'vue';
// import { JQuery } from "../../main";
// import Swal from "sweetalert2";;

export default {
  props: ["property", "assessment_year"],
  setup(props, { emit }) {
    const { amount_in_words } = useFormatter();

    function cancelPrint() {
      emit("cancelPrint");
    }

    function formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    }

    function print() {
      generateQRCode();
      window.$('#details-holder').printThis({
        loadCss: '../../assets/styles/print.css', afterPrint: () => {
          console.log('printing done from here');
        }
      });
    }

    function prepareRef(ref) {

      let oldRef = ref;
      while (oldRef.includes('/')) {
        oldRef = oldRef.replace('/', '&');
      }
      return oldRef;
    }
    function generateQRCode() {
      console.log("generating qrcode");
      //  console.log(window);
      var canvas = window.document.getElementById('qrcode');
      let prepared_ref = prepareRef(props.property.assessment_ref);
      QRCode.toCanvas(

        canvas,
        `https://taxclearance.by.gov.ng/verify-demand-notice/${prepared_ref}`, { scale: 2, color: { light: '#0000', dark: '#000000' } },
        function (error) {
          if (error) console.error(error);
          // console.log("success!");
        }
      );
    }

    async function print2() {
      let style = `

          .details-holder {
            position: relative;
            width: auto !important;
            text-overflow: hidden;
           break-after: page !important;
           font-family: ${"Times New Roman,Times,serif"};
          }

          .notice-img {
            position: absolute;
            width: 21cm !important;
            height: 29.7cm !important;
            top: -0.12cm !important;
            left: -0.12cm !important;
          }

          .content-holder {
            position: absolute;
            top: 0cm !important;
            width: auto !important;
          }
          .sub-content-holder {
            position: absolute;
            padding: 0cm !important;
            top: 6.4cm !important;
            left: 4cm !important;
            height: auto !important;
            width: auto !important;
            font-weight: bold;
            font-size: 1.2em !important;
          }

          .property-use {
            margin-top: -0.1cm;
          }
          .property-area {
            margin-top: -0.5cm;
          }
          .property-parcel-num {
            margin-top: -0.4cm;
          }

      `;

      await printJS({
        printable: "details-holder",
        type: "html",
        header: null,
        style,
        font_size: "",
        onError(err) {
          console.log(err);
        },
        onPrintDialogClose() {
          console.log("printing done...");
        },
      });
    }

    onMounted(() => {
      generateQRCode();
    })


    return {
      cancelPrint,
      formatAmount,
      amount_in_words,
      print,
      print2,
    };
  },
};
</script>

<style scoped>
.qrcode {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 100px;
  height: 100px;
}

.canvas {
  margin-top: 10px !important;
}
</style>
