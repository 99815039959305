<template>
  <content-holder v-if="canViewPropertyDetails" :title="'Property Details'" class="p-6">
    <property-tax :enablePrint="false" :enableAssessmentYear="false"></property-tax>
  </content-holder>
  <content-holder v-else :title="'Property Details'" class="p-6">
    <unauthorized-access></unauthorized-access>
  </content-holder>
</template>

<script>
import PropertyTax from "../components/assessment/PropertyTax.vue";
import ContentHolder from "../components/ContentHolder.vue";
import UnauthorizedAccess from "../components/shared/UnauthorizedAccess.vue";
import { views } from "../helpers/login_details";
export default {
  components: { PropertyTax, ContentHolder, UnauthorizedAccess },
  data: () => ({
    canViewPropertyDetails: false,
  }),
  created() {
    if (views.includes("PropertyDetails")) {
      this.canViewPropertyDetails = true;
    } else {
      this.canViewPropertyDetails = false;
    }
  },
};
</script>

<style>
</style>
