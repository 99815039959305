<template>
    <div class="relative">
        <div class="h-screen w-full fixed z-40 inset-0 overflow-y-auto bg-gray-500">
            <div class="absolute w-full h-full inset-0 bg-gray-500 opacity-75"></div>
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                </span>
                <div class="animate__animated animate__zoomIn animate__faster inline-block relative overflow-hidden transform transition-all sm:align-middle sm:max-w-lg"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div>
                        <div class="rounded-lg p-4 bg-white shadow ">
                            <div class="bg-white dark:bg-gray-800 ">
                                <div class="w-full flex items-center justify-between sm:pl-8 pl-4">
                                    <p class="text-lg text-gray-600 ">
                                        Property Tax Batch Print
                                    </p>
                                    <!-- <i  class="far fa-times-circle text-red-400 text-2xl font-bold cursor-pointer"></i> -->
                                </div>
                                <div class="text-left w-full mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8 z-20">
                                    <div class="text-sm mb-2 text-gray-600 border-b pb-2">Enter building Ids or <span
                                            @click="printBatch"
                                            class="text-primary font-medium cursor-pointer hover:font-bold hover:text-md">click
                                            here</span>
                                        to skip and
                                        print batch </div>
                                    <div class="w-full sm:flex sm:items-end sm:space-x-4 relative  sm:mb-0">
                                        <div class="w-full flex flex-col items-start">
                                            <label for="amount" class="leading-7 text-sm text-gray-dark">
                                                Building ID</label>
                                            <input type="text" id="buildingId" ref="buildingId"
                                                placeholder="Enter building ID" v-model="buildingId" autocomplete="off"
                                                class="w-full bg-white rounded border border-gray-300 focus:border-gray-600 focus:ring-2 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                        </div>
                                        <div class="w-full flex flex-col items-start">
                                            <label for="amount" class="leading-7 text-sm text-gray-dark">Assessment
                                                year</label>
                                            <input type="number" id="assessment_year" name="assessment_year"
                                                v-model="assessment_year" @keyup.enter="addItemToList"
                                                autocomplete="off"
                                                class="w-full bg-white rounded border border-gray-300 focus:border-gray-600 focus:ring-2 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                        </div>

                                        <button @click.prevent="addItemToList"
                                            class="w-full sm:w-auto mt-4 sm:mt-0 block transition duration-300 ease-in-out focus:outline-none bg-gray-600 text-white text-md text-center py-2 px-4 cursor-pointer rounded hover:bg-gray-700 ">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="h-56 px-0.5 py-2 overflow-y-auto hide-scrollbar mt-4 lg:flex-shrink-0">
                                        <div
                                            class="container flex flex-col mx-auto w-full items-start justify-between bg-white dark:bg-gray-800 rounded-lg shadow">
                                            <ul class="w-full flex flex-col divide divide-y">
                                                <li v-for="(item, index) in buildingIds" :key="index">
                                                    <div
                                                        class="select-none cursor-pointer flex flex-row items-center justify-start p-4">
                                                        <span>{{ index + 1 }}.</span>
                                                        <div
                                                            class="w-4/5 ml-4 sm:flex sm:flex-row flex-col sm:items-center justify-start ">
                                                            <div class="w-2/3 flex-1 sm:pl-1 ">
                                                                <div class="font-medium dark:text-white text-left">
                                                                    {{ item }}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <button class="w-24 text-right flex justify-end">
                                                            <i @click="removeItem(item, index)"
                                                                class="far fa-times-circle text-red-400 text-xl font-bold cursor-pointer"></i>
                                                        </button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between gap-4 w-full mt-8">
                                        <button @click="generateBatchNotices" :disabled="generating" type="button"
                                            class="py-2 px-4  bg-primary hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                            <div v-if="!generating">Generate</div>
                                            <div v-else class="flex items-center space-x-4 justify-center">
                                                <i class="fas fa-circle-notch animate-spin"></i>
                                                <span class="hidden sm:block">Please wait...</span>
                                            </div>
                                        </button>
                                        <button @click="closeModal" type="button"
                                            class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-red-500 focus:ring-offset-red-200 text-red-500  w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import numeral from "numeral";
import Swal from "sweetalert2";
import { emitter } from '../../main';

export default {

    emits: ["close-modal", "print-batch", "open-batch-preview"],
    data: () => ({
        generating: false,
        assessment_year: new Date().getFullYear(),
        buildingId: "",
        buildingIds: [],
    }),

    methods: {
        formatAmount(amt) {
            return numeral(amt).format("0,0.00");
        },
        closeModal() {
            this.buildingIds = [];
            this.$emit("close-modal");
        },
        printBatch() {
            this.generating = true;
            this.$emit("print-batch");
        },
        addItemToList() {
            if ((this.assessment_year > 0) & (this.buildingId.length > 3)) {
                this.buildingIds.push(this.buildingId.toUpperCase());
                this.buildingId = "";
                this.$refs.buildingId.focus();

            }
        },
        removeItem(item, index) {
            this.buildingIds.splice(index, 1);
        },
        async generateBatchNotices() {
            try {


                if (this.buildingIds.length > 0) {
                    this.generating = true;

                    let payload = {
                        assessment_year: this.assessment_year,
                        building_ids: this.buildingIds,
                    };
                    let result = await this.$store.dispatch(
                        "assessment/getPropertyTaxResult",
                        { url: 'assessment/propertyTax/batchById', data: payload }
                    );
                    this.$emit("open-batch-preview", result);
                } else {
                    Swal.fire({ title: "Revenue House", icon: "warning", text: "Please enter building Ids" });
                }

            } catch (error) {
                this.generating = false;
                Swal.fire({ title: "Revenue House", icon: "warning", text: error });
            }
        },
    },
    mounted() {
        emitter.on('stop-progress', () => {
            this.generating = false;
        });
    }

};
</script>
